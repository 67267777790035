// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MessageForm from './components/MessageForm'; // Updated import path

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:name" element={<MessageForm />} /> {/* Define the route with a dynamic parameter */}
      </Routes>
    </Router>
  );
};

export default App;