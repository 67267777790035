import React from 'react';

const RightGrassIcon = () => (
<svg width="45" height="265" viewBox="0 0 45 265" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_23_8420)">
<path d="M131.129 3.69267C153.768 15.5481 140.929 45.6909 130.392 61.2278C123.593 71.2496 115.232 80.0019 106.18 88.0172C109.707 85.8697 113.297 83.835 116.981 81.9625C128.904 75.9114 146.458 72.4098 153.034 87.7527C156.049 94.7912 155.14 102.553 152.629 109.598C149.885 117.3 145.668 124.328 140.587 130.689C146.042 127.674 153.235 126.863 158.535 130.685C176.379 143.549 146.334 170.681 137.018 178.999C134.133 181.577 131.168 184.056 128.149 186.465C134.014 183.252 141.119 180.956 145.463 187.032C150.213 193.672 144.857 202.389 140.989 208.028C126.735 228.808 108.515 246.433 86.151 258.281C79.5533 261.776 68.9321 268.412 61.9218 261.832C55.3558 255.668 60.7828 246.091 64.637 240.131C68.6535 233.929 73.28 228.145 78.4072 222.845C74.3132 225.342 70.1099 227.655 65.8148 229.768C52.8169 236.161 34.3531 246.193 19.9341 238.414C2.20029 228.847 16.6546 208.081 25.0225 197.696C34.4836 185.957 44.5935 174.711 55.3946 164.185C52.6335 165.829 49.8336 167.408 46.9844 168.9C36.5324 174.362 23.6825 180.932 11.5379 177.963C3.14887 175.921 -0.176437 168.42 0.00693089 160.352C0.243193 149.911 5.58202 140.316 11.3687 131.934C11.4251 131.849 11.4851 131.768 11.5415 131.683C21.602 117.13 34.7446 104.665 48.275 93.3772C46.4025 93.9625 44.4772 94.3892 42.4954 94.6149C29.7584 96.0571 18.7246 87.2943 22.7798 73.8062C25.9923 63.125 34.0569 54.4926 41.7831 46.8017C58.2791 30.3727 76.2809 13.3124 98.0734 3.74204C109.114 -1.10663 121.88 -1.15247 131.129 3.69267Z" fill="#DAED01"/>
</g>
<defs>
<clipPath id="clip0_23_8420">
<rect width="164.09" height="264.55" fill="white" transform="translate(0 0.0813599)"/>
</clipPath>
</defs>
</svg>


);

export default RightGrassIcon;