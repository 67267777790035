import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getFirestore, 
  collection, 
  addDoc, 
  serverTimestamp 
} from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfRPbBsEJgIvp1D7nNe3KAN5AmyJ_mWh8",
  authDomain: "anonymity-42756.firebaseapp.com",
  projectId: "anonymity-42756",
  storageBucket: "anonymity-42756.appspot.com",
  messagingSenderId: "291341237194",
  appId: "1:291341237194:web:cd7e72d7b1e0f043d72d33",
  measurementId: "G-JDT2RBRQ5M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { 
  db, 
  collection, 
  addDoc, 
  serverTimestamp,
  analytics
};