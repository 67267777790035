import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../index.css";
import DiceIcon from '../icons/DiceIcon';
import PookieIcon from '../icons/PookieIcon'; 
import Logo from '../icons/Logo'; 
import RightGrassIcon from '../icons/RightGrassIcon'; 
import LeftGrassIcon from '../icons/RightGrassIcon'; 
import axios from 'axios';
import { Loader2 } from 'lucide-react'; // For spinner

import { 
  db, 
  collection, 
  addDoc, 
  serverTimestamp 
} from '../config/firebase';

// Custom Popup Component
const Popup = ({ type, message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  const popupStyles = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white'
  };

  return (
    <div className={`fixed top-4 right-4 p-4 rounded-lg shadow-lg z-50 ${popupStyles[type]}`}>
      {message}
    </div>
  );
};

const MessageForm = () => {
    const { name } = useParams();
    const [message, setMessage] = useState("");
    const [userMetadata, setUserMetadata] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [popup, setPopup] = useState(null);

    // Comprehensive User Metadata Collection
    const collectUserMetadata = () => {
        try {
            const metadata = {
                // Device and Browser Information
                platform: navigator.platform,
                userAgent: navigator.userAgent,
                language: navigator.language,
                
                // Screen Details
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
                
                // Performance and System Hints
                hardwareConcurrency: navigator.hardwareConcurrency || 'Unknown',
                deviceMemory: navigator.deviceMemory || 'Unknown',
                
                // Timezone and Locale
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                
                // Unique Identifier Generation
                fingerprint: [
                    navigator.userAgent,
                    navigator.language,
                    new Date().getTimezoneOffset(),
                    !!window.sessionStorage,
                    !!window.localStorage,
                    JSON.stringify(navigator.plugins)
                ].join('|'),
                
                // Timestamp for additional uniqueness
                timestamp: Date.now()
            };

            // Geolocation (Optional - requires user permission)
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        metadata.location = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy
                        };
                    },
                    (error) => {
                        console.warn("Geolocation error:", error);
                    }
                );
            }

            return metadata;
        } catch (error) {
            console.error("Error collecting metadata:", error);
            return null;
        }
    };

    // Collect metadata on component mount
    useEffect(() => {
        const metadata = collectUserMetadata();
        setUserMetadata(metadata);
    }, []);

    const handleRandomMessage = () => {
        const randomMessages = [
            "Hey there! 👋",
            "Feeling mysterious today... 🕵️",
            "What's on your mind? 🤔",
            "Secrets welcome! 🤫",
        ];
        const randomMessage =
            randomMessages[Math.floor(Math.random() * randomMessages.length)];
        setMessage(randomMessage);
    };
    // Location Name Lookup
    const getLocationName = async (latitude, longitude) => {
      try {
          const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
              params: {
                  format: 'json',
                  lat: latitude,
                  lon: longitude,
                  zoom: 10,
                  addressdetails: 1
              },
              headers: {
                  'User-Agent': 'SecretMessageApp/1.0'
              }
          });

          const address = response.data.address;
          
          // Construct a readable location string
          const locationParts = [
              address.city || address.town || address.village || 'Unknown City',
              address.state || 'Unknown State',
              address.country || 'Unknown Country'
          ].filter(Boolean);

          return {
              fullAddress: response.data.display_name,
              shortLocation: locationParts.join(', '),
              details: {
                  city: address.city || address.town || address.village,
                  state: address.state,
                  country: address.country,
                  postcode: address.postcode
              }
          };
      } catch (error) {
          console.error('Error fetching location details:', error);
          return null;
      }
  };


  const handleSendMessage = async () => {
    if (!message.trim()) {
        setPopup({ 
            type: 'error', 
            message: 'Message cannot be empty! ❌' 
        });
        return;
    }

    setIsLoading(true);

    try {
        await addDoc(collection(db, "messages"), {
            message,
            timestamp: serverTimestamp(),
            username: name,
            userMetadata: userMetadata,
            additionalContext: {
                referrer: document.referrer,
                currentURL: window.location.href
            }
        });
        
        setPopup({ 
            type: 'success', 
            message: 'Message sent successfully! 🚀' 
        });
        setMessage(""); // Clear input after sending
    } catch (error) {
        console.error("Error sending message: ", error);
        setPopup({ 
            type: 'error', 
            message: 'Failed to send message. Please try again later. ❌' 
        });
    } finally {
        setIsLoading(false);
    }
};

    return (
        <div className="container">

    
                <RightGrassIcon className="right-grass-icon" />
            <LeftGrassIcon />
            <div className="message-card">
              
                {/* Fixed position RightGrassIcon */}
                {popup && (
                <Popup 
                    type={popup.type} 
                    message={popup.message} 
                    onClose={() => setPopup(null)} 
                />
            )}
                <div className="avatar">
                    <PookieIcon/>
                </div>
                <h2 className="displayname">Hey @{name}</h2>
                <h1 className="secretmessage">Drop me some secret messages. 😉</h1>
                <div className="message-input">
                    <textarea
                        placeholder="Write here...✍️"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button className="dice-button" onClick={handleRandomMessage}>
                        <DiceIcon />
                    </button>
                </div>
                <button className="send-button" onClick={handleSendMessage}>
                    Send!
                </button>
                <div className="logo">
                    <Logo/>
                </div>
                <div className="footer">
                    <h3 className="footer_message">Get your own message!</h3>
                    <p>🔥 500 people just tapped the button 🔥</p>
                    <div className="links">
                        <a href="#">Terms</a>
                        <a href="#">Privacy</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageForm;